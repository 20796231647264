import React, { useState, useEffect, useRef } from 'react'
import { ApiGetCall, ApiGetCallAuthKey } from '../JS/Connector';
import { HideLoder, LogInUserID, ShowLoder } from '../JS/Common';
import Cookies from 'js-cookie';
import AlertsComp from '../Components/AlertsComp';
import { typewatch } from '../JS/Common';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertPopup from './AlertPopup';
export function Schoolsearchbar(props) {
  const [SearchForSchoolName, setSearchForSchoolName] = useState("");
  const [SuggestionBoxArray, setSuggestionBoxArray] = useState([]);
  const [innerload, setinnerload] = useState(false);
  const [SchoolSuggestiobBox, setSchoolSuggestiobBox] = useState({
    ShowSuggestion: "d-none",
  });
  const [SchoolData, setSchoolData] = useState({
    Schoolname: '',
    SchoolID: '',
  });
  const [loading, setLoading] = useState(false);
  const [isStatusPopup, setisStatusPopup] = useState(false);
  const [Alerts, setAlerts] = useState("");
  const SchoolContactid = Cookies.get('SchoolContactID');
  const storedSchoolname = Cookies.get('Schoolname');
  const suggestionBoxRef = useRef(null);
  useEffect(() => {
    const storedSearchValue = Cookies.get('AdminSchoolId');
    setSchoolData({ ...SchoolData, Schoolname: storedSchoolname, SchoolID: storedSearchValue })
    const handleClickOutside = (event) => {
      if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
        setSchoolSuggestiobBox({ ...SchoolSuggestiobBox, ShowSuggestion: "d-none" })
      }
    };
    const handleWindowClick = (event) => {
      handleClickOutside(event);
    };
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, [])

  async function showSchoolName(searchString) {
    if (searchString == "") {
      searchString = null;
    }
    setSchoolSuggestiobBox({ ...SchoolSuggestiobBox, ShowSuggestion: "" });
    setSuggestionBoxArray([])
    typewatch(async function () {
      setinnerload(true);
      await ApiGetCall("/getallSchools/" + searchString + "&" + LogInUserID).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          var sugData = responseRs.msg;
          var sugArray = [];
          var i = 1;

          if (sugData.length != 0) {
            for (var i = 0; i < sugData.length; i++) {
              sugArray.push(
                <div className="row brdr-Btm font-14" key={i} id={sugData[i].id} name={sugData[i].name} olduserid={sugData[i].oldestUser} onClick={(e) => showSchoolData(e)} >
                  <div className="col-8 text-start" style={{ padding: "8px 15px" }}>{sugData[i].name}</div>
                </div>
              )
            }
            setSuggestionBoxArray(sugArray);
          } else {
            sugArray.push(
              <div className="col-12 text-center" key={i}>
                <label>No Record Found</label>
              </div>
            );
            setSuggestionBoxArray(sugArray);
          }
          setinnerload(false);
        }
      });
    }, searchString != null ? 500 : 0);
  }

  function showSchoolData(e) {
    setSchoolSuggestiobBox({ ...SchoolSuggestiobBox, ShowSuggestion: 'd-none' });
    var id = parseInt(e.currentTarget.attributes[1].value);
    var name = e.currentTarget.attributes[2].value;
    var contactid = e.currentTarget.attributes[3].value;
    var expires = 1;
    Cookies.set('SchoolContactID', contactid, { expires });
    Cookies.set('AdminSchoolId', id, { expires });
    Cookies.set('Schoolname', name, { expires });
    props.schlid(id);
    if (props.flag == 1) {
      props.GetGridData(id, 1, null, 2);
    } else if (props.flag == 2) {
      props.GetGridData(id, 1, null, 3);
    } else if (props.tabflag == 1) {
      props.settabflag(1)
      props.GetGridData(null, 1, props.batchid, null)
    } else if (props.tabflag == 2) {
      props.settabflag(2)
      props.GetGridData(null, 2, props.batchid, null)
    } else if (props.tabflag == 3) {
      props.settabflag(3)
      props.GetGridData(null, 3, props.batchid, null)
    } else if (props.tabflag == 4) {
      props.settabflag(4)
      props.GetGridData(null, 4, props.batchid, null)
    } else if (props.flag == 5) {
      props.GetGridData(id, null, null, 1)
    }
    setSchoolData({ ...SchoolData, Schoolname: name, SchoolID: id })
    if (props.setSelectedIdsOpen) {
      props.setSelectedIdsOpen([])
    }
    if (props.setSelectedIdsPedning) {
      props.setSelectedIdsPedning([])
    }
    if (props.setSelectedIdsSentOut) {
      props.setSelectedIdsSentOut([])
    }
    if (props.setSelectedIdsAtVendor) {
      props.setSelectedIdsAtVendor([])
    }
    if (props.setSelectedIdsOpen1) {
      props.setSelectedIdsOpen1([])
    }
    if (props.setSelectedIdsPedning1) {
      props.setSelectedIdsPedning1([])
    }
    if (props.setSelectedIdsSentOut1) {
      props.setSelectedIdsSentOut1([])
    }
    if (props.setSelectedIdsAtVendor1) {
      props.setSelectedIdsAtVendor1([])
    }
  }

  async function LoginIntoSchool() {
    setLoading(true);
    await ApiGetCallAuthKey("/setK12LoginasSchoolLogin/" + LogInUserID + "&" + SchoolContactid + "&1").then((result) => {
      setLoading(false);
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        var sugData = responseRs.k12data;
        if (responseRs.status == "success") {
          Cookies.set('CsvUserId', sugData.id, { expires: 1 });
          Cookies.set('schoolid', responseRs.schooldata.school_id, { expires: 1 });
          if (responseRs.schooldata.school != null) {
            Cookies.set('Schoolnumber', responseRs.schooldata.school.school_number, { expires: 1 });
          } else {
            Cookies.set('Schoolnumber', '', { expires: 1 });
          }
          window.location.href = '/manage-tickets';
        } else {
          setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.status} />);
          setTimeout(() => {
            setAlerts(<AlertsComp show={false} />)
          }, 2000);
        }

      }
    });
  }
  return (
    <>
      {Alerts}
      {loading ? <ShowLoder /> : <HideLoder />}
      <div className="row align-items-center px-3">
        <div className="col-md-5 px-0">
          <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
            <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search searchIconClass"></i></div>
            <input className="form-control" autoComplete="off" type="text" placeholder="Search School" style={{ backgroundColor: '#f9fafb' }}
              value={SearchForSchoolName} onChange={(e) => { setSearchForSchoolName(e.target.value) }}
              onKeyUp={(e) => showSchoolName(e.target.value)} />
            <div className={`SuggestionBoxDiv ${SchoolSuggestiobBox.ShowSuggestion}`} style={{ zIndex: '1' }} ref={suggestionBoxRef}>
              {innerload && (
                <div className='row'>
                  <div className="col-12 text-center pt-2">
                    <img src="images/loder.svg" className="" style={{ width: "30px" }} />
                  </div>
                </div>
              )}

              {SuggestionBoxArray}
            </div>
          </form>
        </div>
        <div className='col-md-6 d-flex SearchBarDiv schoolNameClass pe-0 align-items-center'><h6 className="fw-500 mb-0">{SchoolData.Schoolname}</h6>
          {SchoolData.Schoolname ?
            props.SchoolLoginFlag == 1 && (
              <FontAwesomeIcon icon={faArrowRightToBracket} title="Login as school Admin" className='parent-icon cursor-pointer ps-2' onClick={() => setisStatusPopup(true)} />
            ) : <></>
          }
        </div>
      </div >

      <AlertPopup isshow={isStatusPopup} img={'/images/greenInfo.svg'}
        headingtext={'Login as School Admin'} text={`Are you sure want to login as ${SchoolData.Schoolname}?`}
        cancelcall={(e) => setisStatusPopup(false)}
        canceltext={"No"} successtext={"Continue"} successcall={LoginIntoSchool} btntype={'SaveBtn'} />
    </>
  )
}
