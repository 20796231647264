import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
import { ApiPostCall } from '../../JS/Connector';
import { CheckValidation } from '../../Components/Validations';
import { HideLoder, RemoveAllCookies, ShowLoder } from '../../JS/Common';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function StaticLogin() {
    const [loading, setLoading] = useState(false);
    const [AlertMsgs, setAlertMsgs] = useState("");
    const [AlertColor, setAlertColor] = useState("");
    const [userid, setuserid] = useState('');
    const [password, setpassword] = useState('');
    useEffect(() => {
        RemoveAllCookies();
        Cookies.remove('isloadtesting');
    }, []);
    const onSuccess = async () => {
        var isFormValid = CheckValidation({ formID: 'loginuserid' });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            userid: userid,
            password: password,
        });
        await ApiPostCall("/StaticLogin", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                var sugData = responseRs.schooldata;
                var userData = responseRs.k12data;
                var expires = 1;
                if (responseRs.status == "success") {
                    Cookies.set('ShippingType', sugData?.school?.shipping_type, { expires });
                    Cookies.set('ciphertext', userData?.remember_token, { expires });
                    Cookies.set('CsvUserId', sugData.id, { expires: 1 });
                    Cookies.set('schoolid', sugData.school_id, { expires: 1 });
                    if (sugData.school != null) {
                        Cookies.set('Schoolnumber', sugData.school.school_number, { expires: 1 });
                    } else {
                        Cookies.set('Schoolnumber', '', { expires: 1 });
                    }
                    Cookies.set('isloadtesting', 1, { expires: 1 });
                    if (sugData.access_type == 5 || sugData.access_type == 6) {
                        window.location = '/school-tickets';
                    } else {
                        window.location = '/';
                    }
                }
                else {
                    setAlertColor("red");
                    setAlertMsgs(responseRs.msg);
                }
            }
        });
    }
    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
            <div className="position-relative MainDiv">
                <div className="card mx-auto">
                    <div className="p-4 row">
                        <div className="col-12 text-center">
                            <div style={{ color: `${AlertColor}` }} className="font-16" dangerouslySetInnerHTML={{ __html: AlertMsgs }} />
                        </div>
                        <div className="my-4">
                            <img src="/images/NewLogo.svg" className="img-fluid" alt="Logo" />
                        </div>
                        <div className="col-md-8 offset-md-2 text-center mt-3">
                            <div className='row parent' id="loginuserid">
                                <div className='col-md-12 mb-2'>
                                    <input type="text" autoComplete='off' placeholder='Enter User id' name='serialnumber' className="form-control" required value={userid}
                                        onChange={(e) => setuserid(e.target.value)} />
                                    <span className="form-text invalid-feedback">
                                        *required
                                    </span>
                                </div>
                                <div className='col-md-12 mb-2'>
                                    <input type="password" autoComplete='off' placeholder='Enter Password' name='password' className="form-control" required value={password}
                                        onChange={(e) => setpassword(e.target.value)} />
                                    <span className="form-text invalid-feedback">
                                        *required
                                    </span>
                                </div>
                            </div>
                            <div onClick={onSuccess} className="MicrosoftGoogleBtn">
                                <img src="/images/GoogleBtn.svg" className="img-fluid pe-2" /> Login
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
