import React, { Fragment, useState } from 'react'
import Helpdeskdetails from './Helpdeskdetails';
import MasterinventoryDetails from './Masterinventorydetails';
import SendOutForRepairDetails from './SendOutForRepairDetails';
import UncoveredRepairDetails from './UncoveredRepairDetails';
import ArchiveUsersDetails from './ArchiveUsersDetails';
import SignatureDetails from './SignatureDetails';
import SchoolDetails from './SchoolDetails';
import PageHead from '../../Components/PageHead';
import PaymentLink from './PaymentLink';

export function Setting() {
    const [NewAlerts, setNewAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    return (
        <Fragment>
            <PageHead heading={"Settings"} loading={loading} Alerts={NewAlerts} showsearch={false} />
            <div className="container-fluid px-0 mt-2" id="GridDiv">
                <div className="GridBox p-3">
                    <div className="row">
                        <div className='col-md-6 py-2'>
                            <Helpdeskdetails setLoading={setLoading} setNewAlerts={setNewAlerts} />
                        </div>
                        <div className='col-md-6 py-2'>
                            <MasterinventoryDetails setLoading={setLoading} setNewAlerts={setNewAlerts} />
                        </div>
                        <div className='col-md-6 py-2'>
                            <SendOutForRepairDetails setLoading={setLoading} setNewAlerts={setNewAlerts} />
                        </div>
                        <div className='col-md-6 py-2'>
                            <UncoveredRepairDetails setLoading={setLoading} setNewAlerts={setNewAlerts} />
                        </div>
                        <div className='col-md-6 py-2'>
                            <SignatureDetails setLoading={setLoading} setNewAlerts={setNewAlerts} />
                        </div>
                        <div className='col-md-6 py-2'>
                            <PaymentLink setLoading={setLoading} setNewAlerts={setNewAlerts} />
                        </div>
                        <div className="col-12 py-2">
                            <ArchiveUsersDetails setLoading={setLoading} setNewAlerts={setNewAlerts} />
                        </div>
                        <div className="col-12 py-2">
                            <SchoolDetails setLoading={setLoading} setNewAlerts={setNewAlerts} />
                        </div>
                    </div>
                    <div className='col-12 py-2'>
                        <div className='greyBox '>
                            <div className="row align-items-center">
                                <div className='col-md-6'>
                                    <b className='footerlabel' onClick={(e) => window.open('/terms-of-service')}>Terms of Service</b>
                                    <label className='px-2 font-18'>|</label>
                                    <b className='footerlabel' onClick={(e) => window.open('/privacy-policy')}>Privacy Policy</b>
                                </div>
                                <div className='col-md-6 d-flex align-items-center justify-content-end'>
                                    <b>FOLLOW US</b>
                                    <div className='d-flex align-items-center justify-content-evenly ps-4'>
                                        <a href="https://www.facebook.com/k12techrepair/" target="_blank" rel="noopener" data-linktype="social" data-socialnetwork="Facebook">
                                            <img src='/images/facebook.svg' className='img-fluid mx-3' style={{ height: "20px", width: "20px" }} />
                                        </a>
                                        <a href="https://twitter.com/K12TechRepairs" target="_blank" rel="noopener" data-linktype="social" data-socialnetwork="Twitter">
                                            <img src='/images/twitter.svg' className='img-fluid mx-3' style={{ height: "20px", width: "20px" }} />
                                        </a>
                                        <a href="https://www.instagram.com/k12techrepairs/" target="_blank" rel="noopener" data-linktype="social" data-socialnetwork="Instagram">
                                            <img src='/images/instagram.svg' className='img-fluid mx-3' style={{ height: "20px", width: "20px" }} />
                                        </a>
                                        <a href="https://www.linkedin.com/company/k-12-tech/" target="_blank" rel="noopener" data-linktype="social" data-socialnetwork="Linkedin">
                                            <img src='/images/linkedin.svg' className='img-fluid mx-3' style={{ height: "20px", width: "20px" }} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Fragment>
    )
}
