import { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha";
import { CheckValidation } from "../../Components/Validations";
import { HideLoder, isValidEmail, ShowLoder } from "../../JS/Common";
import AlertsComp from "../../Components/AlertsComp";
import { ApiPostCallAuthKey } from "../../JS/Connector";
import { useNavigate } from "react-router-dom";
export function Contactus() {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const schoolid = pathArray[2];
    const plannumber = pathArray[3];
    const [ContactData, setContactData] = useState({
        StudentNumber: "",
        Question: "",
        email: ""
    })
    var captchasitekey = process.env.REACT_APP_Captcha_Sitekey;
    const [CaptchaKey, setCaptchaKey] = useState("");
    const [blankCaptcha, setblankCaptcha] = useState("");
    const [Loading, setLoading] = useState(false);
    const [NewAlerts, setNewAlerts] = useState("");
    const [EmailValidation, setEmailValidation] = useState("");
    const navigate = useNavigate();
    function handleRecaptchaChange(value) {
        setCaptchaKey(value);
    }
    async function handleSubmit() {
        var isFormValid = CheckValidation({ formID: 'contactuspage' });
        var emailvalid = isValidEmail(ContactData.email);
        if (CaptchaKey == "") {
            setblankCaptcha("Before you proceed, please complete the captcha");
            isFormValid = false;
        } else {
            setblankCaptcha("");
        }
        if (emailvalid == false) {
            setEmailValidation("Invalid Email")
            isFormValid = false;
        } else {
            setEmailValidation("");
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            StudentNumber: ContactData.StudentNumber,
            Question: ContactData.Question,
            fromEmail: ContactData.email,
            PlanID: plannumber,
            Schoolid: schoolid,
            Captcha: CaptchaKey
        });
        await ApiPostCallAuthKey("/contactUs", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Your Feedback sent Successfully"} />)
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />);
                        navigate('/thank-you')
                    }, 2000);
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.status} />);
                }
            }
        });
    }
    return (
        <>
            {NewAlerts}
            {Loading ? <ShowLoder /> : <HideLoder />}
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className={window.innerWidth >= 767 ? 'm-3 px-1' : ''}>
                        <div className={window.innerWidth >= 767 ? 'GridBox p-4 px-5 m-auto mx-5' : 'GridBox p-4 px-5 m-auto'}>
                            <div className="col-12 text-center">
                                <img src="/images/LoginLogo.png" className="img-fluid" alt="Logo" />
                            </div>
                            <div className="text-center mb-4 mt-5">
                                <h1 className="mb-0" style={{ color: "#4C5258" }}>Contact Us</h1>
                                <hr />
                            </div>
                            <div className="col-md-8 offset-md-2 parent" id="contactuspage">
                                <div className="col-12 pb-3">
                                    <div className='FormLabel text-start'>Student No.<label className='redText'>*</label></div>
                                    <input type="text" autoComplete='off' name='studentnumber' required className="form-control"
                                        value={ContactData.StudentNumber} onChange={(e) => { setContactData({ ...ContactData, StudentNumber: e.target.value }) }} />
                                    <span className="form-text invalid-feedback">
                                        *required
                                    </span>
                                </div>
                                <div className="col-12 py-3">
                                    <div className='FormLabel text-start'>What is the question or problem at hand?<label className='redText'>*</label></div>
                                    <textarea required className="form-control" name="issue" value={ContactData.Question} onChange={(e) => { setContactData({ ...ContactData, Question: e.target.value }) }} />
                                    <span className="form-text invalid-feedback">
                                        *required
                                    </span>
                                </div>
                                <div className="col-12 py-3">
                                    <div className='FormLabel text-start'>Email<label className='redText'>*</label></div>
                                    <input type="text" autoComplete='off' name='email' className="form-control"
                                        value={ContactData.email} onChange={(e) => { setContactData({ ...ContactData, email: e.target.value }) }} />
                                    <span style={{ fontSize: '.875em', color: '#dc3545' }}>
                                        {EmailValidation}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                    <ReCAPTCHA sitekey={captchasitekey} onChange={handleRecaptchaChange} />
                                </div>
                                <div className="text-center mb-3">
                                    <span style={{ fontSize: '.875em', color: '#dc3545' }}>{blankCaptcha}</span>
                                </div>
                                <div className="col-12 text-center">
                                    <button type='submit' className='SaveBtn px-5 cursor-pointer' onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
