import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ApiGetCallAuthKey } from '../../JS/Connector';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import VideoUpload from '../SupportTicket/VideoUpload';
import ReCAPTCHA from 'react-google-recaptcha';
import { CheckValidation } from '../../Components/Validations';
import { HideLoder, ShowLoder, getAllBuildings, isValidEmail } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import { GoogleLoginForSupportTicket } from '../Signin/GoogleLoginForSupportTicket';
import Cookies from 'js-cookie';
import { MicrosoftLoginForSupportTicket } from '../Signin/MicrosoftLoginForSupportTicket';
const Dotsloder = () => {
    return (
        <section className="dots-container pb-5">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </section>
    )
}
const Category = ({ data, onClick, labelname, set, setString, setter, selectedId, resetCategories, setShowDetailsDiv }) => {
    return (
        <div className='row py-3'>
            <div className="col-12">
                <div className='col-md-5 questionchatbox'>
                    <img src='/images/GreenSmallLogo.svg' className='img-fluid questionlogo' />
                    {labelname}
                </div>
            </div>
            <div className='col-12 my-2'>
                {data.length > 0 ?
                    <div className={`ps-0 d-flex flex-wrap ${set[setString]?.activeID ? 'justify-content-end' : ''}`}>{
                        data.map((item, index) => (
                            set[setString]?.activeID ?
                                <div className='text-end' key={index}>
                                    <div className="float-end" key={index}>
                                        <button className={`helpDesk-button px-3 py-2 cursor-pointer ${item[selectedId] == set[setString]?.activeID ? "active" : "d-none"} m-2 `} key={index}
                                            title={item.name}>
                                            <label>
                                                {item.icon &&
                                                    <img src={item.icon} className='img-icon' />
                                                }
                                            </label>
                                            <span className='ps-2'>{item.name}</span>
                                            {item[selectedId] == set[setString]?.activeID && (
                                                <>
                                                    <img src='/images/BackButton.svg' onClick={() => { setter((prevState) => ({ ...prevState, [setString]: { ...prevState[setString], activeID: null } })); resetCategories(); setShowDetailsDiv('d-none'); }} className='img-fluid cursor-pointer ps-2' style={{ height: "15px" }} />
                                                    {labelname != 'Issues' && (
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="auto"
                                                            overlay={
                                                                <Popover id={`popover-${item.name}`}>
                                                                    <Popover.Header as="h3">{item.name}</Popover.Header>
                                                                    <Popover.Body>{item.description ? <div dangerouslySetInnerHTML={{ __html: item.description.replace(/<img /g, "<img class='img-fluid'") }} /> : 'No Description Found'}</Popover.Body>
                                                                </Popover>
                                                            }>
                                                            <img src='/images/whiteinfo.svg' className='img-fluid cursor-pointer ps-1' style={{ height: "18px" }} />
                                                        </OverlayTrigger>
                                                    )}
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className='py-2 align-items-center mx-2' key={index}>
                                    <button className={`helpDesk-button`} key={index}
                                        onClick={() => { onClick(item); setter((prevState) => ({ ...prevState, [setString]: { ...prevState[setString], activeID: item[selectedId] } })); }} title={item.name}>
                                        <label>
                                            {item.icon &&
                                                <img src={item.icon} className='img-icon' />
                                            }
                                        </label>
                                        <span className='ps-2'>{item.name}</span>
                                        {labelname != 'Issues' && (
                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="auto"
                                                overlay={
                                                    <Popover id={`popover-${item.name}`}>
                                                        <Popover.Header as="h3">{item.name}</Popover.Header>
                                                        <Popover.Body>{item.description ? <div dangerouslySetInnerHTML={{ __html: item.description.replace(/<img /g, "<img class='img-fluid'") }} /> : 'No Description Found'}</Popover.Body>
                                                    </Popover>
                                                }>
                                                <img src='/images/greyinfo.svg' className='img-fluid ps-2' style={{ height: "16px" }} />
                                            </OverlayTrigger>
                                        )}
                                    </button>
                                </div>
                        ))
                    }</div>
                    :
                    <div className='col-12'>No record found</div>
                }
            </div>
        </div>
    )
};
export default function HelpdeskForm() {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const schoolnumber = pathArray[2];
    const [SchoolData, setSchoolData] = useState({
        schoolid: '',
        schoolname: '',
        logo: '',
        loginflag: '',
        microsoftgoogleflag: ''
    });
    const emptyObj = {
        "data": [],
        "visible": false,
        "activeID": null
    };
    const [MainData, setMainData] = useState({
        MainCategoriesData: emptyObj
    });
    const [Alldata, setAlldata] = useState({
        Name: '',
        Email: '',
        Flag: 2,
        Roomno: '',
        StudentNumber: '',
        Document: '',
        Filename: '',
        Description: '',
        loginAccesstoken: '',
        Title: '',
        Buildingname: '',
        Buildingid: ''
    })
    const [isLoader, setLoader] = useState(true)
    const [Alerts, setAlerts] = useState('')
    const [ShowData, setShowData] = useState({ Description: null, IsResolved: false })
    const [ResolvedButtonVal, setResolvedButtonVal] = useState("")
    const chatBoxRef = useRef(null);
    const [Video, setVideo] = useState('');
    const [CaptchaKey, setCaptchaKey] = useState('');
    const [blankCaptcha, setblankCaptcha] = useState("");
    const [EmailValidation, setEmailValidation] = useState("");
    const [BuildingValidation, setBuildingValidation] = useState("");
    const [ShowDetailsDiv, setShowDetailsDiv] = useState('d-none');
    const [loading, setLoading] = useState(false);
    const [LoginFlag, setLoginFlag] = useState(2);
    const [microsoftgoogleflag, setmicrosoftgoogleflag] = useState(2);
    const [isCheckedForOther, setIsCheckedForOther] = useState(false);
    const [Buildings, setBuildings] = useState([]);
    const rawCookie = Cookies.get('helpTicketDetails');
    var captchasitekey = process.env.REACT_APP_Captcha_Sitekey;
    const BaseUrl = process.env.REACT_APP_Base_URL;
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", process.env.REACT_APP_AuthKey);
    useLayoutEffect(() => {
        ApiGetCallAuthKey("/schoolDatabyNumber/" + schoolnumber).then((result) => {
            try {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const { msg, logo, ssoType } = JSON.parse(result);
                    setSchoolData({
                        schoolid: msg.id,
                        schoolname: msg.name,
                        logo: logo || null
                    });
                    if (msg.help_desk == null) {
                        setLoginFlag(0);
                    } else {
                        setLoginFlag(msg.help_desk);
                    }
                    setmicrosoftgoogleflag(ssoType);
                    fetchDataForLink("MainCategoriesData", null, 3, msg.id);
                    getAllBuildings(setBuildings, msg.id);
                }
            } catch (e) {
                console.log(e)
            }
        });

    }, []);
    useEffect(() => {
        if (LoginFlag == 0) {
            GetCookiesData();
        }
    }, [LoginFlag]);
    useEffect(() => {
        if (!ShowData?.Description && ShowData?.IsResolved) {
            setShowDetailsDiv('');
        } else if (ShowData?.IsResolved && ResolvedButtonVal == "No") {
            setShowDetailsDiv('');
        } else if (ResolvedButtonVal == 'Yes') {
            setShowDetailsDiv('d-none');
            setAlerts(<AlertsComp show={true} variant="success" msg={"Details Sent Successfully."} />);
            setTimeout(function () {
                setAlerts(<AlertsComp show={false} />)
                window.location.href = `/submithelpdesk`;
            }, 2500);
        }
    }, [MainData, ShowData, ResolvedButtonVal, isLoader, ShowDetailsDiv]);
    async function fetchDataForLink(set, item, apiflag, schoolId = null) {
        let flag = false;
        try {
            schoolId == null && setLoader(true);
            const SId = schoolId == null ? SchoolData.schoolid : schoolId
            await ApiGetCallAuthKey("/GetDataById/" + SId + "&" + item + "&" + apiflag).then((result) => {
                if (!result) {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    if (responseRs.length != 0) {
                        responseRs.sort((a, b) => {
                            if (a.name < b.name) return -1;
                            if (a.name > b.name) return 1;
                            return 0;
                        });
                        setMainData((prevState) => ({ ...prevState, [set]: { ...prevState[set], data: responseRs, visible: true, activeID: null } }));
                        flag = false
                    } else {
                        if (item != null) {
                            flag = true
                        }
                    }
                }
            });
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false);
            setShowData({ Description: null, IsResolved: flag })
        }

    }
    const updateCategories = (clickedCategory) => {
        setMainData((prevData) => {
            const updatedData = { ...prevData };
            for (const key in updatedData) {
                if (clickedCategory == "MainCategoriesData" && key !== clickedCategory) {
                    updatedData[key] = { ...emptyObj };
                }
                else if (clickedCategory == "HelpDeskCategoryData" && key !== clickedCategory && key !== "MainCategoriesData") {
                    updatedData[key] = { ...emptyObj };
                }
                else if (clickedCategory == "SubCategoryData" && key !== clickedCategory && key !== "MainCategoriesData" && key !== "HelpDeskCategoryData") {
                    updatedData[key] = { ...emptyObj };
                }
            }
            return updatedData;
        });
        setShowData({ Description: null, IsResolved: false })
        setResolvedButtonVal("")
    };
    function handlePdfFileUpload(e) {
        const file = e.target.files[0];
        if (file.size > 2 * 1024 * 1024) {
            alert("File size exceeds 2MB limit.");
            return;
        }
        const reader = new FileReader();
        reader.onload = function (event) {
            setAlldata({ ...Alldata, Document: file, Filename: file.name });
        };
        reader.readAsDataURL(file);
    };
    function handleRecaptchaChange(value) {
        setCaptchaKey(value);
    }
    function SubmitHelpdesk() {
        var isFormValid = CheckValidation({ formID: 'HelpDeskForm' });
        var emailvalid = isValidEmail(Alldata.Email);
        if (CaptchaKey == "") {
            setblankCaptcha("Before you proceed, please complete the captcha");
            isFormValid = false;
        } else {
            setblankCaptcha("");
        }
        if (emailvalid == false) {
            setEmailValidation("Invalid Email")
            isFormValid = false;
        } else {
            setEmailValidation("");
        }
        if (Alldata.Buildingid === '') {
            setBuildingValidation("*required");
            isFormValid = false;
        } else {
            setBuildingValidation("");
        }
        if (!isFormValid) return;
        setLoading(true);
        var categoryid = "";
        var subcategoryid = "";
        var issueid = "";
        if (MainData?.HelpDeskCategoryData) {
            categoryid = MainData?.HelpDeskCategoryData?.activeID;
        }
        if (MainData?.SubCategoryData) {
            subcategoryid = MainData?.SubCategoryData?.activeID;
        }
        if (MainData?.IssueData) {
            issueid = MainData?.IssueData?.activeID;
        }

        const formdata = new FormData();
        formdata.append('schoolid', SchoolData.schoolid);
        formdata.append('typeid', MainData?.MainCategoriesData?.activeID);
        formdata.append('categoryid', categoryid);
        formdata.append('subcategoryid', subcategoryid);
        formdata.append('issueid', issueid);
        formdata.append('requester_type', Alldata.Flag);
        formdata.append('name', Alldata.Name);
        formdata.append('email', Alldata.Email);
        formdata.append('roomno', Alldata.Roomno);
        formdata.append('studentno', Alldata.StudentNumber);
        formdata.append('document', Alldata.Document);
        formdata.append('video', Video);
        formdata.append('description', Alldata.Description);
        formdata.append('title', Alldata.Title);
        formdata.append('captcha', CaptchaKey);
        formdata.append('building', Alldata.Buildingid);
        formdata.append('buildingname', Alldata.Buildingname);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        fetch(`${BaseUrl}/SaveHelpdeskRequests`, requestOptions).then(response => response.text())
            .then(result => {
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Helpdesk sent successfully"} />);
                    setTimeout(function () {
                        setAlerts(<AlertsComp show={false} />)
                        window.location.href = `/submithelpdesk/${responseRs.schoolnumber}/${responseRs.hdrequestId}/${responseRs.hdrequestnum}`;
                    }, 2500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }
    function GetCookiesData() {
        if (rawCookie != undefined) {
            const decodedString = decodeURIComponent(rawCookie);
            const userDetailsForhelpdesk = JSON.parse(decodedString);
            setAlldata({ ...Alldata, Name: userDetailsForhelpdesk.name, Email: userDetailsForhelpdesk.email, loginAccesstoken: userDetailsForhelpdesk.accessToken })
        }
    }
    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className="row">
                {LoginFlag == 0 ?
                    <div className="helpdesk-container">
                        <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%" }}>
                            <img src='/images/helpdeskbg.svg' alt="" style={{ objectFit: "cover", width: "100%" }} />
                        </div>
                        <div className='position-relative' style={{ paddingLeft: "15%", paddingRight: "10%" }}>
                            <div className='col-12 pt-4 px-4'>
                                <div className='col-12 font-14' style={{ color: "#6A6A6A" }}>
                                    Helping hand from
                                    <img src='/images/HelloHand.svg' className='ps-2 img-fluid byebye-animation' />
                                </div>
                                <div className='col-12 mt-2 '>
                                    <h4 className='fw-600 mb-0'>{SchoolData.schoolname}</h4>
                                </div>
                                <label style={{ color: "#6A6A6A", fontWeight: "500" }} className='font-13'>Choose one of the options below that best addresses your inquiry.</label>
                            </div>
                            {MainData?.MainCategoriesData?.data.length > 0 ?
                                <>
                                    <div className="p-4 row">
                                        <div className="col-12">
                                            {MainData?.MainCategoriesData?.visible && (
                                                <Category resetCategories={() => updateCategories("MainCategoriesData")} data={MainData?.MainCategoriesData?.data}
                                                    onClick={(item) => { MainData?.MainCategoriesData?.activeID != item?.id && fetchDataForLink("HelpDeskCategoryData", item?.id, 4) }}
                                                    labelname="Hello, what type of help do you need today?" set={MainData} setString="MainCategoriesData" setter={setMainData} selectedId={"id"} setShowDetailsDiv={setShowDetailsDiv} />
                                            )}
                                            {MainData?.HelpDeskCategoryData?.visible && (
                                                <Category resetCategories={() => updateCategories("HelpDeskCategoryData")} data={MainData?.HelpDeskCategoryData?.data}
                                                    onClick={(item) => { MainData?.HelpDeskCategoryData?.activeID != item?.id && fetchDataForLink("SubCategoryData", item?.id, 2) }}
                                                    labelname="Please choose Category from below options:" set={MainData} setString="HelpDeskCategoryData" setter={setMainData} selectedId={"id"} setShowDetailsDiv={setShowDetailsDiv} />
                                            )}
                                            {MainData?.SubCategoryData?.visible && (
                                                <Category resetCategories={() => updateCategories("SubCategoryData")} data={MainData?.SubCategoryData.data}
                                                    onClick={(item) => { MainData?.SubCategoryData?.activeID != item?.id && fetchDataForLink("IssueData", item?.id, 2) }}
                                                    labelname="Please choose Subcategory from below options:" set={MainData} setString="SubCategoryData" setter={setMainData} selectedId={"id"} setShowDetailsDiv={setShowDetailsDiv} />
                                            )}
                                            {MainData?.IssueData?.visible && (
                                                <Category resetCategories={() => updateCategories("IssueData")} data={MainData?.IssueData?.data}
                                                    onClick={(item) => { setShowData({ Description: item?.description, IsResolved: true }); setResolvedButtonVal("") }}
                                                    labelname="Please choose the type of issue from below options:" set={MainData} setString="IssueData" setter={setMainData} selectedId={"id"} setShowDetailsDiv={setShowDetailsDiv} />
                                            )}
                                            {ShowData?.Description && <>
                                                <div className="row py-3">
                                                    <div className='col-md-5 questionchatbox' style={{ background: "white" }}>
                                                        <div dangerouslySetInnerHTML={{ __html: ShowData?.Description.replace(/<img /g, "<img class='img-fluid'") }} />
                                                    </div>
                                                </div>
                                            </>}
                                            {(ShowData?.Description && ShowData?.IsResolved) && (
                                                <div className='row py-3'>
                                                    <div className="col-12">
                                                        <div className='col-md-5 helpDesk-button active p-3'>
                                                            Did this solution resolve the issue?
                                                        </div>
                                                    </div>
                                                    <div className='col-12 my-2'>
                                                        <div className='row'>
                                                            <div className='col-6' />
                                                            <div className='col-6 justify-content-end d-flex'>
                                                                <button className={`helpDesk-button ${ResolvedButtonVal == 'Yes' ? 'active' : ""} my-3 me-2`} value="Yes" onClick={(e) => setResolvedButtonVal(e.target.value)} title='Yes, Close Request'>Yes, Close Request</button>
                                                                <button className={`helpDesk-button ${ResolvedButtonVal == 'No' ? 'active' : ""} my-3`} value="No" onClick={(e) => setResolvedButtonVal(e.target.value)} title='No,Open Device Ticket'>No,Open Device Ticket</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {ResolvedButtonVal && <>
                                                <div className="row my-3">
                                                    <div className='col-md-5 helpDesk-button active p-3'>
                                                        {ResolvedButtonVal == "Yes" ? "We are happy to help you! Have a great day! 😄" : "Thank you for your time. Your ticket has been submitted to management, we will reach out to you shortly with updates!"}
                                                    </div>
                                                </div>
                                            </>}
                                            {isLoader && <div className="row">
                                                <div className="col-1"><Dotsloder /></div>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className={`py-2 ${ShowDetailsDiv} GridBox `}>
                                        <div className='col-12 text-center'>
                                            <u> <b className=''>Create your ticket below:</b></u>
                                        </div>
                                        <div className="col-12 font-15 px-5 text-center row pt-3">
                                            <div className='d-flex ps-4 align-items-center font-13'>
                                                <b className='pe-5 font-12' style={{ color: "#495057" }}>Role:</b>
                                                <div className="form-check">
                                                    <input className="form-check-input cursor-pointer" type="radio" name='role' defaultChecked={Alldata.Flag == 2} onChange={(e) => { setAlldata({ ...Alldata, Flag: 2 }) }} />
                                                    <label className="form-check-label"> Teacher</label>
                                                </div>
                                                <div className="form-check ms-3">
                                                    <input className="form-check-input cursor-pointer" type="radio" name='role' defaultChecked={Alldata.Flag == 1} onChange={(e) => { setAlldata({ ...Alldata, Flag: 1 }) }} />
                                                    <label className="form-check-label"> Student / Parent </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`px-5 parent `} id='HelpDeskForm' >
                                            <div className='row'>
                                                <div className="align-items-center row py-2 px-4">
                                                    <b className="font-12" style={{ color: "#495057" }}>
                                                        Is there a specific building for this issue?</b>
                                                </div>
                                                <div className='col-12 pb-2 px-4'>
                                                    <div className="row">
                                                        {Buildings.map((item, i) => {
                                                            return <div className="col-md-3 py-1 font-13">
                                                                <input type="radio" className="form-check-input me-2"
                                                                    onChange={(e) => { setAlldata({ ...Alldata, Buildingid: item.id, Buildingname: '' }); setIsCheckedForOther(false); }} name={"building"} bid={item.id}
                                                                />
                                                                {item.name}
                                                            </div>
                                                        })}
                                                        <div className="col-md-3 py-1 font-13">
                                                            <input type="radio" className="form-check-input me-2" name={"building"}
                                                                checked={isCheckedForOther} onChange={(e) => { setAlldata({ ...Alldata, Buildingid: 0, Buildingname: '' }); setIsCheckedForOther(e.target.checked) }}
                                                            />
                                                            Other
                                                        </div>
                                                        {isCheckedForOther == true && (
                                                            <div className='col-4'>
                                                                <input type="text" placeholder="Enter Building*" required autoComplete='off' name='building' className="form-control" value={Alldata.Buildingname} onChange={(e) => setAlldata({ ...Alldata, Buildingname: e.target.value })} />
                                                                <span className="form-text invalid-feedback">
                                                                    *required
                                                                </span>
                                                            </div>
                                                        )}
                                                        <span style={{ fontSize: '.875em', color: '#dc3545' }}>{BuildingValidation}</span>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 py-3'>
                                                    <div className='col-12'>
                                                        <input type="text" placeholder="Title*" autoComplete='off' name='title' className="form-control" required value={Alldata.Title} onChange={(e) => setAlldata({ ...Alldata, Title: e.target.value })} />
                                                        <span className="form-text invalid-feedback">
                                                            *required
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 py-3'>
                                                    <div className='col-12'>
                                                        <input type="text" placeholder="Name*" autoComplete='off' name='name' className="form-control" required value={Alldata.Name} onChange={(e) => setAlldata({ ...Alldata, Name: e.target.value })} />
                                                        <span className="form-text invalid-feedback">
                                                            *required
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 py-3'>
                                                    <div className='col-12'>
                                                        <input type="email" placeholder="Email*" autoComplete='off' name='email' className="form-control" value={Alldata.Email} onChange={(e) => setAlldata({ ...Alldata, Email: e.target.value })} />
                                                        <span style={{ fontSize: '.875em', color: '#dc3545' }}>{EmailValidation}</span>
                                                    </div>
                                                </div>
                                                {Alldata.Flag != 1 && (
                                                    <div className='col-md-4 py-3'>
                                                        <div className='col-12'>
                                                            <input type="text" placeholder="Room No. *" autoComplete='off' name='roomno' className="form-control" required value={Alldata.Roomno} onChange={(e) => setAlldata({ ...Alldata, Roomno: e.target.value })} />
                                                            <span className="form-text invalid-feedback">
                                                                *required
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className='col-md-4 py-3'>
                                                    <div className='col-12'>
                                                        {Alldata.Flag != 1 ?
                                                            <input type="text" placeholder="Student No." autoComplete='off' name='studentno' className="form-control" value={Alldata.StudentNumber} onChange={(e) => setAlldata({ ...Alldata, StudentNumber: e.target.value.toUpperCase() })} />
                                                            :
                                                            <>
                                                                <input type="text" placeholder="Student No.*" required autoComplete='off' name='studentno' className="form-control" value={Alldata.StudentNumber} onChange={(e) => setAlldata({ ...Alldata, StudentNumber: e.target.value.toUpperCase() })} />
                                                                <span className="form-text invalid-feedback">
                                                                    *required
                                                                </span>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-4 py-3'>
                                                    <input type="file" label="img" id="UploadFileId" name="myFile" accept=".jpg,.png,.svg,.jpeg" onChange={(e) => handlePdfFileUpload(e)} />
                                                    <label className="supportTicketbtn col-md-12" htmlFor="UploadFileId" style={{ color: "#909091" }}>
                                                        Choose File (Attach Image)
                                                    </label>
                                                    {Alldata.Filename && <label className="ps-2 mt-1" style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>{Alldata.Filename} </label>}
                                                </div>
                                                <VideoUpload selectedFile={(e) => setVideo(e)} />
                                                <div className="col-md-8 pb-3">
                                                    <textarea placeholder="Ticket Description" type="textarea" rows={3} autoComplete="off" name="RepairedNm" className="form-control" value={Alldata.Description} onChange={(e) => setAlldata({ ...Alldata, Description: e.target.value })} />
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <ReCAPTCHA sitekey={captchasitekey} onChange={handleRecaptchaChange} />
                                                </div>
                                                <div className="text-center">
                                                    <span style={{ fontSize: '.875em', color: '#dc3545' }}>{blankCaptcha}</span>
                                                </div>
                                                <div className="col-md-12 text-center mt-2 pt-3 justify-content-center align-items-center">
                                                    <button className="SaveBtn mt-2" onClick={SubmitHelpdesk}>Submit Ticket</button>
                                                    <label className="ms-2 cursor-pointer" onClick={(e) => window.location.reload()}> <u>Reset</u></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <div className='col-12 text-center m-5'>No Helpdesk found</div>
                            }
                        </div>
                    </div>
                    :
                    LoginFlag == 1 ?
                        microsoftgoogleflag == 1 ?
                            <>
                                <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
                                <div className="position-relative MainDiv">
                                    <GoogleLoginForSupportTicket flag="helpdesk" setLoginFlag={setLoginFlag} setallData={setAlldata} allData={Alldata} />
                                </div>
                            </>
                            :
                            <>
                                <LazyLoadImage src="/images/LoginBg.png" alt="" className="MainDiv-bg-image" />
                                <div className="position-relative MainDiv">
                                    <MicrosoftLoginForSupportTicket flag="helpdesk" setLoginFlag={setLoginFlag} />
                                </div>
                            </>
                        :
                        <></>
                }
            </div >

        </>
    )
}
