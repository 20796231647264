import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { TechnologySupportTicket } from '../SupportTicket/Technology';
import { MaintenanceSupportTicket } from '../SupportTicket/Maintenance';
import HelpdeskIndex from '../Helpdesk';
import HelpdeskTicket from '../Helpdesk/HelpdeskTicket';
import Helpdeskrequest from '../Helpdesk/Helpdeskrequest';
import ManageSoftware from '../ManageSoftware';
import { ErrorPage } from '../404Error';
export default function Access9() {
    return (
        <Routes>
            <Route exact path="/" element={<TechnologySupportTicket />} />
            <Route exact path="/technology-support-tickets" element={<TechnologySupportTicket />} />
            <Route exact path="/maintenance-support-tickets" element={<MaintenanceSupportTicket />} />
            <Route exact path="/helpdesk" element={<HelpdeskIndex />} />
            <Route exact path="/add-helpdesk" element={<HelpdeskTicket />} />
            <Route exact path="/helpdesk-requests" element={<Helpdeskrequest />} />
            <Route exact path="/manage-software" element={<ManageSoftware />} />
            <Route exact path="*" element={<ErrorPage />} />
        </Routes>
    )
}
