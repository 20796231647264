import React from 'react'
import { ApiGetCall } from '../../JS/Connector';
import { LogInUserID, MMDDYYYY } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import GreyBoxForReports from './GreyBoxForReports';

export default function MassTickets({ setLoading, FilterData, setalerts }) {
    async function GetTicketData() {
        setLoading(true);
        await ApiGetCall("/AllTicketDetailsReport/" + MMDDYYYY(FilterData.StartDate) + '&' + MMDDYYYY(FilterData.EndDate) + '&' + FilterData.Location + '&' + FilterData.School + '&' + LogInUserID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.status == "success") {
                    setalerts(<AlertsComp show={true} variant="success" msg={`You will receive an email notification on ${responseRs.email} once the data is ready.`} />);
                    setTimeout(() => {
                        setalerts(<AlertsComp show={false} />);
                    }, 8000);
                } else {
                    setalerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
                    setTimeout(() => {
                        setalerts(<AlertsComp show={false} />);
                    }, 2000);
                }
                setLoading(false);
            }
        });
    }
    return (
        <GreyBoxForReports icon="faTicket" name={"Bulk Tickets Report Download"} onclickfunc={GetTicketData} />
    )
}
