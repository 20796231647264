import { useState } from "react";
import { HideLoder, ShowLoder } from "../../JS/Common";
import MicrosoftLogin from "react-microsoft-login";
import { ApiPostCallAuthKey } from "../../JS/Connector";
import AlertsComp from "../../Components/AlertsComp";
import Cookies from "js-cookie";

export function MicrosoftLoginForSupportTicket(props) {
    const pathname = window.location.pathname;
    const pathArray = pathname.split('/');
    const schoolnumber = pathArray[2];
    const [Alerts, setAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const [AlertColor, setAlertColor] = useState('');
    const [alertsMsg, setAlertsMsg] = useState('');
    const clientId = process.env.REACT_APP_MicrosoftClientId;
    const redirectUri = process.env.REACT_APP_Redirecturi;
    const [hasRegistered, setHasRegistered] = useState(false);
    const handleLoginSuccess = async (error, authData, msalInstance) => {
        if (hasRegistered) return;
        if (authData) {
            var loginaccessToken = authData.accessToken;
            var rawForShow = JSON.stringify({
                name: authData.account.name,
                email: authData.account.userName,
                accessToken: loginaccessToken,
            });
            var raw = JSON.stringify({
                SchoolNum: schoolnumber,
                LoginEmail: authData.account.userName
            })
            await ApiPostCallAuthKey("/ssoLoginValidation", raw).then((result) => {
                setHasRegistered(true);
                if (result == undefined || result == "") {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={'Login Failed!'} />);
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false)
                    if (responseRs.status == "success") {
                        const expires = 1;
                        Cookies.set('supportTicketDetails', rawForShow, { expires });
                        setAlertColor('green')
                        setAlertsMsg(responseRs.msg)
                        setTimeout(function () {
                            props.setLoginFlag(0)
                        }, 2500);
                    }
                    else {
                        setAlertColor('red')
                        setAlertsMsg(responseRs.msg)
                    }
                }
            });
        }
    };
    const handleHelpdeskLogin = async (error, authData, msalInstance) => {
        if (hasRegistered) return;
        if (authData) {
            var loginaccessToken = authData.accessToken;
            var rawForShow = JSON.stringify({
                name: authData.account.name,
                email: authData.account.userName,
                accessToken: loginaccessToken,
            });
            var raw = JSON.stringify({
                SchoolNum: schoolnumber,
                LoginEmail: authData.account.userName
            })
            await ApiPostCallAuthKey("/helpdeskLoginValidation", raw).then((result) => {
                setHasRegistered(true);
                if (result == undefined || result == "") {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={'Login Failed!'} />);
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false)
                    if (responseRs.status == "success") {
                        const expires = 1;
                        Cookies.set('helpTicketDetails', rawForShow, { expires });
                        setAlertColor('green')
                        setAlertsMsg(responseRs.msg)
                        setTimeout(function () {
                            props.setLoginFlag(0)
                        }, 2500);
                    }
                    else {
                        setAlertColor('red')
                        setAlertsMsg(responseRs.msg)
                    }
                }
            });
        }
    };
    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            {Alerts}
            <div className="card mx-auto">
                <div className="p-4 row">
                    <div className="col-12 text-center">
                        <div style={{ color: `${AlertColor}` }} className="font-16" >{alertsMsg}</div>
                    </div>
                    <div className="my-4">
                        <img src="/images/LoginLogo.png" className="img-fluid" alt="Logo" />
                    </div>
                    <div className="fw-600">
                        {props.flag == "supportticket" ?
                            <label>To create a support ticket, please sign in with microsoft first.</label>
                            :
                            <label>To create a helpdesk, please sign in with microsoft first.</label>
                        }
                    </div>
                    <div className="col-md-8 offset-md-2 text-center mt-4">
                        <div style={{ padding: '10px 75px 10px 75px', fontSize: '13px', border: '1px solid #e2e3e4', borderRadius: '16px', backgroundColor: '#f9fafb', fontFamily: '"Poppins", sans-serif', cursor: 'pointer' }}>
                            <MicrosoftLogin
                                clientId={clientId}
                                redirectUri={redirectUri}
                                authCallback={props.pageflag == "supportticket" ? handleLoginSuccess : handleHelpdeskLogin}
                                prompt="select_account"
                                buttonTheme="light"
                                className="CustomMicrosoftBtn"
                            >
                                <img src="/images/MicrosoftBtn.svg" alt="Microsoft" className="img-fluid pe-3" />
                                <span>Sign in with Microsoft</span>
                            </MicrosoftLogin>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
